@font-face {
    font-family: 'GT Walsheim';
    src: local('GT Walsheim'),
        url('./Fonts/Gt/GT-WALSHEIM-BOLD.TTF') format('truetype');
}
@font-face {
    font-family: 'GT Walsheim';
    src: local('GT Walsheim'),
        url('./Fonts/Gt/GT-WALSHEIM-LIGHT.TTF') format('truetype');
}
@font-face {
    font-family: 'GT Walsheim';
    src: local('GT Walsheim'),
        url('./Fonts/Gt/GT-WALSHEIM-MEDIUM.TTF') format('truetype');
}
@font-face {
    font-family: 'GT Walsheim';
    src: local('GT Walsheim'),
        url('./Fonts/Gt/GT-WALSHEIM-REGULAR.TTF') format('truetype');
}

body {
    margin: 0;
    font-family: 'GT Walsheim';
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}
